<template>
    <div class="container">
        <div class="row">
            <div class="col-md-5 text-center mt-5" id='hero'>
                <h2>Wachtwoord resetten</h2>
                <h5>Als je jouw e-mailadres invult, sturen wij je een link waarmee je jouw wachtwoord kunt resetten.</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5 " id='login-bar'>
                <div class="row">
                    <form @submit.prevent="submit">
                        <div class="col-md-12 d-grid mt-5 mb-3" id='login-grid'>
                            <input placeholder='E-mailadres' v-model="form.identity" id='identity' type='text' class='form-control'/>
                        </div>
                        <div class="col-md-12">
                            <p v-if="showError" id="error">{{errorMessage}}</p>
                        </div>
                        <div class="col-md-12">
                            <button :disabled='isDisabled' class='btn btn-primary w-100 btn-lg mb-2' type="submit">Versturen <font-awesome-icon v-if="isDisabled" icon="fa-solid fa-spinner" pulse/></button>
                             <hr>
                        </div>
                        <div class="col-md-12 text-center mb-5">
                          <p><small>E-mail niet ontvangen? Het kan een minuut duren voordat het bericht binnen is, controleer ook jouw spam-folder. Werkt dit niet? Neem dan contact met ons op: <a href="https://skipistenieuwegein.nl/contact-openingstijden" target='_blank'>naar contactpagina.</a></small></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      form: {
        identity: ''
      },
      loading: false,
      showError: false,
      errorMessage: 'Er ging iets fout'
    }
  },
  computed: {
    isDisabled: function () {
      return this.loading
    }
  },
  methods: {
    async submit () {
      this.loading = true
      const User = {
        Email: this.form.identity
      }
      this.$store.dispatch('auth/forgotPassword', User).then((res) => {
        if (res.status === 200) {
          this.showError = false
          this.loading = false
          this.$router.push({
            name: 'login',
            params: { bigMessage: res.data.message }
          })
        } else if (res.status === 500) {
          this.errorMessage = res.response.data.message
          this.showError = true
          this.loading = false
        } else {
          this.errorMessage = 'Er ging iets fout'
          this.showError = true
          this.loading = false
        }

        return true
      }).catch(() => {
        console.log('catch')
        this.errorMessage = 'Er ging iets fout'
        this.showError = true
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
    #forgot-password{
        font-size: 0.9rem;
        text-align: center;
        padding: 4px 12px;
    }
    #hero h1{
        font-size: 5rem;
        font-weight: 600;
    }
    #login-bar{
        background: #fff;
        border-radius: 25px;
        min-height: 300px;
    }
    #login-grid{
        grid-template-columns: 1fr;
        grid-gap: 12px;
        grid-template-rows: auto;
    }
    @media screen and (max-width: 992px){
         #login-grid{
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }
         #hero h1{
        font-size: 3rem;
        font-weight: 600;
    }
    }
</style>
